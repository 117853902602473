<template>
  <div>
    <b-navbar type="dark" variant="dark">



      <b-container>
        <b-row>
          <b-col cols=1>
            <b-navbar-brand class="mx-auto"  > Nous contacter</b-navbar-brand>

          </b-col>
        </b-row>

      </b-container>


      <th></th>


    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "Kisfooter"
}
</script>

<style scoped>

</style>