<template>
  <div id="app" class="bg">
    <header>
      <kisbar></kisbar>
    </header>


<b-container>

<b-row>

  <b-col id="sectionuser">
    <kisvideo id="user" videosrc="Presentation_generale_user.mp4"
              textml=" <h4>Ce tuto vous fait découvrir pas à pas la solution KISDIS en mode User afin de vous familiariser avec la solution et ainsi connaitre tous ce qu'il vous est possible de faire. Attention : les modes User peuvent différer d'une entreprise à une autre en fonction des préférences de celle-ci. </h4>"></kisvideo>

  </b-col>

  <hr class="style-one">
</b-row>
    <b-row>
    <b-col id="site">
              <kisvideo  videosrc="Comment_creer_un_site.mp4"
    textml=" <h4> Ce tuto vous explique comment créer un site sur la solution KISDIS. Il vous montre également comment créer des catégories et des répertoires à l'intérieur de votre site, afin que vous puissiez partager diverses informations. Et enfin comment y ajouter des documents de tous types (fichier audio, vidéo, pdf ou encore image).</h4>"></kisvideo>
    </b-col>

      <hr class="style-one">
    </b-row>

  <b-row>
    <b-col id="sectionadmin">
 <kisvideo id="admin" videosrc ="Presentation_generale_admin.mp4"
                        textml=" <h4>Ce tuto vous fait découvrir pas à pas et en détail la solution KISDIS en mode Admin afin de vous familiariser avec la solution et ainsi connaitre tous ce qu'il vous est possible de faire. Les tutos 'Comment créer un site ?' et 'Comment créer un modèle ?' vous permettent d'aller plus loin.</h4>"></kisvideo>
</b-col>
    <hr class="style-one">
  </b-row>
  <b-row>

    <b-col>
      <kisvideo id="modele" videosrc ="/Comment_creer_un_modele.mp4"
                textml=" <h4> Ce tuto vous explique ce qu'est un modèle et à quoi il sert. Vous verrez comment en créer un sur la solution KISDIS. Il vous montre également comment créer des catégories et des répertoires qui seront communs à l'ensemble des sites de votre modèle. Le modèle vous permet de partager diverses informations simultanément sur plusieurs sites, et vous montrera comment ajouter des documents (fichier audio, vidéo, pdf ou encore image). </h4>"></kisvideo>
    </b-col>

    <hr class="style-one">

  </b-row>
  <b-row>
    <b-col id="sectionadmin">
      <kisvideo id="admin" videosrc ="Comment mettre KISDIS en favoris - version Android.mp4"
                textml=" <h4>Ce tuto vous explique comment mettre la solution KISDIS en favoris pour le retrouver facilement et rapidement sur votre téléphone Android. Il vous montre également comment placer la page sur votre écran d’accueil pour y avoir accès en un clic !</h4>"></kisvideo>
    </b-col>
    <hr class="style-one">

  </b-row>

  <b-row>

    <b-col>
      <kisvideo id="modele" videosrc ="Comment mettre KISDIS en favoris - version Iphone.mp4"
                textml=" <h4> Ce tuto vous explique comment mettre la solution KISDIS en favoris pour le retrouver facilement et rapidement sur votre Iphone. Il vous montre également comment placer la page sur votre écran d’accueil pour y avoir accès en un clic ! </h4>"></kisvideo>
    </b-col>


  </b-row>
  <hr class="style-one">

  <b-row>
    <b-col>
      <b-img src="tutojpg/Comment brancher son enceinte a son ecran.jpg" fluid-grow alt="Fluid-grow image"></b-img>
    </b-col>
  </b-row>
  <b-row>
    <b-col>
      <b-img src="tutojpg/Comment brancher son enceinte a son ecran2.jpg" fluid-grow alt="Fluid-grow image"></b-img>
    </b-col>
  </b-row>

  <hr class="style-one">

  <b-row>
    <b-col>
      <b-img src="tutojpg/TUTO 1.7.1.jpg" fluid-grow alt="Fluid-grow image"></b-img>
    </b-col>
  </b-row>

  <hr class="style-one">

  <b-row>
    <b-col>
      <b-img src="tutojpg/TUTO 1.7.12.jpg" fluid-grow alt="Fluid-grow image"></b-img>
    </b-col>
  </b-row>

  <hr class="style-one">

  <b-row>
    <b-col>
      <b-img src="tutojpg/TUTO 1.7.13.jpg" fluid-grow alt="Fluid-grow image"></b-img>
    </b-col>
  </b-row>

  <hr class="style-one">

  <b-row>
    <b-col>
      <b-img src="tutojpg/TUTO 1.7.14.jpg" fluid-grow alt="Fluid-grow image"></b-img>
    </b-col>
  </b-row>
  <hr class="style-one">

  <b-row>
    <b-col>
      <b-img src="tutojpg/TUTO 1.7.15.jpg" fluid-grow alt="Fluid-grow image"></b-img>
    </b-col>
  </b-row>

</b-container>
    <kisfooter></kisfooter>
    <div id="bas"></div>


  </div>

</template>

<script>
import Kisbar from "@/components/Kisbar";
import Kisfooter from "@/components/Kisfooter";


import Kisvideo from "@/components/kisvideo";


export default {
  name: 'App',

  components: {
    Kisvideo,
    Kisbar,
    Kisfooter

  }
}
</script>

<style>
.bg {
  /* The image used */
  background-color: wheat;

  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

hr.style-one {
  border: 0;
  clear:both;
  display:block;
  width: 96%;
  background-color: black;
  height: 2px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>

