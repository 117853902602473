<template>
  <div class="align-items-center p-3">

    <b-row>
      <b-col> <span  v-html="textmltitre"></span></b-col>

    </b-row>
    <b-row>
      <b-col  cols="15">
        <div>
          <video class="embed-responsive" controls>
            <source :src="videosrc"
                    type="video/webm" source>
          </video>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="15" class="p-5">
        <span  class="just" v-html="textml" ></span>
      </b-col>

    </b-row>
    <div>

    </div>
  </div>


</template>

<script>
export default {
  name: "kisvideo",

  props : {
    videosrc: String,
    videodeux: String,
    textml: String,
    textmltitre: String
  } ,
  data(){
    return{


    }
  },
}
</script>

<style scoped>


.just {
  text-align: justify;
  text-justify: inter-word;
}

</style>