<template>
  <div>
    <b-navbar type="dark" variant="info"  fixed="top" >
      <b-navbar-brand >Kisdis</b-navbar-brand>
      <b-navbar-nav>
      <b-nav-item color="white" href="#user" class="a">Mode User</b-nav-item>
      <b-nav-item color="white" href="#site" class="a">Création Site</b-nav-item>
      <b-nav-item color="white" href="#admin" class="a">Mode Admin</b-nav-item>
      <b-nav-item color="white" href="#modele" class="a">Création Modèle </b-nav-item>
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<script>

export default {
  components:{
  },
  name: "Kisbar"
}
</script>

<style scoped>

a:link {
  color: white;
}

/* visited link */
a:visited {
  color: white;
}

/* mouse over link */
a:hover {
  color: blueviolet;
}

/* selected link */
a:active {
  color: white;
}


</style>